import "./Box.css";

const Box = (props) => {
  return (
    <div className={`Box ${props.className}`}>
      <h3>{props.h3}</h3>
      <ul>
        {props.list?.map((el, key) => (
          <li key={key}>{el}</li>
        ))}
      </ul>
    </div>
  );
};

export default Box;
