import image1 from "../../src/assets/images/frontEndDevDataSlider/image1.jpg";

export const frontEndDevDataSlider = [
  {
    id: "1",
    className: "frontEndDevDataSlider",
    src: image1,
    alt: "Europe Adventures",
    h2: "Europe Adventures",
    href: "https://github.com/Yazan-Egbaria/edventures-repo",
  },
];
