import image1 from "../assets/images/uiUXDataSlider/image1.jpg";
import image2 from "../assets/images/uiUXDataSlider/image2.jpg";
import image3 from "../assets/images/uiUXDataSlider/image3.jpg";

export const uiUXDataSlider = [
  {
    id: "1",
    className: "uiUX",
    src: image3,
    alt: "Macros",
    h2: "Macros",
    href: "https://www.behance.net/gallery/199504205/Macros-Food-Tracker-App",
  },
  {
    id: "2",
    className: "uiUX",
    src: image2,
    alt: "FoodMarket",
    h2: "FoodMarket",
    href: "https://www.behance.net/gallery/199503137/FoodMarket-App",
  },
  {
    id: "3",
    className: "uiUX",
    src: image1,
    alt: "Europe Adventures",
    h2: "Europe Adventures",
    href: "https://www.behance.net/gallery/185151117/Europe-Adventures",
  },
];
