import "./WorkData.css";

const WorkData = (props) => {
  const { data, style } = props;
  return (
    <div className={`thumbNail ${data.className}`} style={style}>
      <a href={data.href} target="_blank" rel="noreferrer">
        <img src={data.src} alt={data.alt} loading="lazy" />
        <div className="thumbNailContent">
          <h2>{data.h2}</h2>
        </div>
      </a>
    </div>
  );
};

export default WorkData;
