import image1 from "../assets/images/graphicDesignDataSlider/image1.jpg";
import image2 from "../assets/images/graphicDesignDataSlider/image2.jpg";
import image3 from "../assets/images/graphicDesignDataSlider/image3.jpg";
import image4 from "../assets/images/graphicDesignDataSlider/image4.jpg";
import image5 from "../assets/images/graphicDesignDataSlider/image5.jpg";

export const graphicDesignDataSlider = [
  {
    id: "1",
    className: "graphicDesign",
    src: image1,
    alt: "Lina Candles",
    h2: "Lina Candles",
    href: "https://www.behance.net/gallery/196362041/Lina-Candles",
  },
  {
    id: "2",
    className: "graphicDesign",
    src: image2,
    alt: "The Station",
    h2: "The Station",
    href: "https://www.behance.net/gallery/196362519/The-Station",
  },
  {
    id: "3",
    className: "graphicDesign",
    src: image3,
    alt: "Advance Co.",
    h2: "Advance Co.",
    href: "https://www.behance.net/gallery/196378339/Advance-Co",
  },
  {
    id: "4",
    className: "graphicDesign",
    src: image4,
    alt: "Swimming Resort",
    h2: "Swimming Resort",
    href: "https://www.behance.net/gallery/196362305/Swimming-Resort",
  },
  {
    id: "5",
    className: "graphicDesign",
    src: image5,
    alt: "Europe Adventures",
    h2: "Europe Adventures",
    href: "https://www.behance.net/gallery/196362823/Europe-Adventures",
  },
];
