import React, { useEffect, useRef, useState } from "react";
import NavItem from "../../components/NavItem/NavItem";
import { Link } from "react-scroll";
import "./Navbar.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isScrolled = scrollTop > 100;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      ref={navbarRef}
      className={`navbar navbar-expand-md ${
        scrolled ? "scrolled" : ""
      } bg-dark navbar-dark text-white`}
    >
      <div className="container">
        <Link
          to="Header"
          spy={true}
          smooth={true}
          offset={-90}
          duration={100}
          className="navbar-brand"
        >
          Yazan
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainmenu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="mainmenu">
          <ul className="navbar-nav ms-auto">
            <NavItem>
              <Link
                to="Header"
                spy={true}
                smooth={true}
                offset={-90}
                duration={100}
                className="nav-link"
              >
                Home
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="About"
                spy={true}
                smooth={true}
                offset={-200}
                duration={100}
                className="nav-link"
              >
                About
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="Skills"
                spy={true}
                smooth={true}
                offset={-300}
                duration={100}
                className="nav-link"
              >
                Skills
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="Work"
                spy={true}
                smooth={true}
                offset={-100}
                duration={100}
                className="nav-link"
              >
                Work
              </Link>
            </NavItem>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
