import "./Footer.css";

const mail = "info@yazanegbaria.com";

const Footer = () => {
  return (
    <div className="footer">
      <p className="footer-content">
        © 2024 Yazan Egbaria. All rights reserved.
      </p>

      <div className="footer-links">
        <a
          href="https://www.behance.net/yazanegbaria"
          target="_blank"
          rel="noreferrer"
        >
          Behance
        </a>
        <a
          href="https://www.linkedin.com/in/yazanegbaria/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        <a
          href="https://github.com/Yazan-Egbaria"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>

        <a href={`mailto:${mail}`} target="_blank" rel="noreferrer">
          Mail
        </a>
      </div>
    </div>
  );
};

export default Footer;
