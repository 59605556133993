const Headercontent = (props) => {
  return (
    <div className={`Headerparagraph ${props.className}`}>
      <span>{props.span}</span>
      <h1>{props.header}</h1>
    </div>
  );
};

export default Headercontent;
