export const graphicDesignSkills = [
  "Visual Communication",
  "Adobe Creative Suite",
  "Brand Identity",
  "Layout Design",
  "Typography",
  "Post Production",
];

export const UIUXSkills = [
  "User Research",
  "Wireframing/Prototyping",
  "Information Architecture",
  "Interaction Design",
  "Usability Testing",
  "Problem Solving",
];

export const frontEndSkills = [
  "HTML/CSS/SCSS",
  "Bootstrap/Tailwind",
  "JavaScript",
  "React",
  "Git/Github",
  "Responsive Design",
];
