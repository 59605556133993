import "./Skills.css";
import Sectiontitle from "../../components/Sectiontitle/Sectiontitle";
import Box from "../../components/SkillsBox/Box";
import { graphicDesignSkills } from "../../data/SkillsData";
import { UIUXSkills } from "../../data/SkillsData";
import { frontEndSkills } from "../../data/SkillsData";

const Skills = () => {
  return (
    <div className="Skills hidden">
      <Sectiontitle h2="Services" className="skills-title" />

      <div className="Triple-box">
        <Box
          className="Graphic-Design"
          h3="Graphic Design:"
          list={graphicDesignSkills}
        />

        <Box className="UI-UX" h3="UI/UX:" list={UIUXSkills} />

        <Box
          className="Front-End-Development"
          h3="Front-End Development:"
          list={frontEndSkills}
        />
      </div>
    </div>
  );
};

export default Skills;
